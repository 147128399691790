import React from 'react'
import * as styles from '../../../sass/layout.module.scss'
import { GatsbyImage } from 'gatsby-plugin-image';


const disclaimer = ({block, raw}) => {
  const {imageMain} = block
  return (
    <section>
        <div className={styles.layoutBody}>
            <div>
                {imageMain && imageMain.asset && (
                    <GatsbyImage 
                    alt="image" 
                    image={imageMain.asset.gatsbyImageData}></GatsbyImage>
                )}
            </div>
       </div>
    </section>
  )
}

export default disclaimer