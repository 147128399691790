import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image';
import * as stylesH from './HomeHero.module.scss'
import { Link } from 'gatsby';

const heroHomes = ({block, raw}) => {
  const {homeheader,subcontent,leftcta,rightcta,hero,leftLink, rightLink} = block

  return (
    <section className={stylesH.homeHero}>
      <div className={stylesH.homeHeroBg}>
        {hero && hero.asset && (
            <GatsbyImage 
         alt={homeheader} 
         image={hero.asset.gatsbyImageData}></GatsbyImage>
            )}
            </div>
          <div className={stylesH.homeHeroContent}>
            <h1>{homeheader}</h1>
      
          
            <p>{subcontent}</p>
            <div className={stylesH.homeHeroButtons}>
            <Link to={leftLink}> <div className={stylesH.homeHeroLeftcta}><p className={stylesH.homeHeroLeftctaText}>{leftcta}</p></div> </Link>
            <Link to={rightLink}> <div className={stylesH.homeHeroRightcta}><p className={stylesH.homeHeroRightctaText}>{rightcta}</p></div> </Link>
            </div>

          </div>
      </section>
  )
}

export default heroHomes