import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import * as stylesB from '../bottomSplitCta/bottomSplitCta.module.scss'


const bottomSplitCta = ({block, raw}) => {
  const {leftheader,leftcontent,leftcta,leftimage,rightheader,rightcontent,rightcta,rightimage, leftLink, rightLink} = block

  return (
    <section className={stylesB.bottomSplitCta}>

        <div className={stylesB.bottomSplitGrid}>

            <div className={stylesB.bottomGridItem}>
                <div className={stylesB.bottomGridImage}>
                {leftimage && leftimage.asset && (
                    <GatsbyImage 
                    alt={leftheader} 
                    image={leftimage.asset.gatsbyImageData}></GatsbyImage>
                )}
                </div>
                <div className={stylesB.bottomGridContent}>
                    <h1>{leftheader}</h1>
                    <p>{leftcontent}</p>
                    <Link to={leftLink}><div className={stylesB.bottomGridCta}>
                        <p>{leftcta}</p>
                    </div></Link>
                </div>  
            </div>

            <div className={stylesB.bottomGridItem}>
                <div className={stylesB.bottomGridImage}>
                {rightimage && rightimage.asset && (
                    <GatsbyImage 
                    alt={leftheader} 
                    image={rightimage.asset.gatsbyImageData}></GatsbyImage>
                )}
                </div>
                <div className={stylesB.bottomGridContent}>
                    <h1>{rightheader}</h1>
                    <p>{rightcontent}</p>
                    <Link to={rightLink}><div className={stylesB.bottomGridCta}>
                        <p>{rightcta}</p>
                    </div></Link>   
                </div>
                
            </div>

        </div>
        
    </section>
  )
}

export default bottomSplitCta