import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image';
import * as styles from '../../../sass/layout.module.scss'
import * as stylesS from '../splitNoCtas/noCtaSplits.module.scss'


const noLeftctaSplit = ({block, raw}) => {
  const {header,subcontentl,image} = block
  return (
    <section className={stylesS.splitterLeft}>
         <div className={styles.layoutBody}>
         
          <div className={stylesS.splitterLeftWrap}>
         
          <div className={stylesS.splitterContentLeft}>

                <h1>{header}</h1>
                {subcontentl.map((val,i) =>{
                        var style = val.style;
                        if(style === 'h1'){
                        return  <h1 key={i} className={stylesS.splitLeftText}> {val.children.map((x,j) => {
                                return<span key={j}>{x.text}</span>; })} </h1>;
                        } else  if(style === 'h2'){  
                            return  <h2 key={i} className={stylesS.splitLeftText}> {val.children.map((x,j) => {
                                return<span key={j}>{x.text}</span>; })} </h2>;

                        } else  if(style === 'h3'){ 
                            return  <h3 key={i} className={stylesS.splitLeftText}> {val.children.map((x,j) => {
                                return<span key={j}>{x.text}</span>; })} </h3>;

                        } else  if(style === 'h4'){ 
                            return  <h4 key={i} className={stylesS.splitLeftText}> {val.children.map((x,j) => {
                                return<span key={j}>{x.text}</span>; })} </h4>; 
                        } else  if(style === 'h5'){  

                            return  <h5 key={i} className={stylesS.splitLeftText}> {val.children.map((x,j) => {
                                return<span key={j}>{x.text}</span>; })} </h5>;
                        } 
                        else{
                            return  <p key={i} className={stylesS.splitLeftText}> {val.children.map((x,j) => {
                                return<span key={j}>{x.text}</span>; })} </p>;
                        }
                        
                        
                        })}

            </div>
            
            <div className={stylesS.splitterLeftImage}>
                {image && image.asset && (
                <GatsbyImage 
                    alt={header} 
                    image={image.asset.gatsbyImageData}>
                </GatsbyImage>
                )}
            </div>
           

        </div>

       </div>

    </section>
  )
}

export default noLeftctaSplit