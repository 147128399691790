import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image';
import * as styles from '../../../sass/layout.module.scss'
import * as stylesW from '../walkingTours/walkingTours.module.scss'
import "../../../../src/styles/global.scss"


const walkingTours = ({block, raw}) => {
  const {header,subcontent,tours} = block
  let id ='';
  let id2 ='';
  if(header === 'The Project'){
    id = 'walking-image-project';
    id2 = 'walking-project-content'
  }
  if(header !== null){
  return (
    <section className={stylesW.walkingTours}>
         <div className={styles.layoutBody}>
            
         <h1>{header}</h1>
         <p>{subcontent}</p>

           <div className={stylesW.walkingToursWrapper}>
                    
                {tours.map(tour => {

                    let assetLink

                    if(tour.file) {
                        assetLink = <a href={`${tour.file.asset.url}?dl=`}>{tour.label}</a>
                    } else {
                        assetLink = ''
                    }
                        
                return(
                    <div className={stylesW.walkingTourItem}>

                        <div id={id} className={stylesW.walkingTourImage}>   
                           {tour.photo && tour.photo.asset && (
                             <GatsbyImage alt={tour.title} image={tour.photo.asset.gatsbyImageData}></GatsbyImage>)}
                        </div>     
                           
                            
                            <div id={id2} className={stylesW.walkingTourContent}>
                                <div id={id2} className={stylesW.walkingTourText}>
                                    <h2>{tour.title}</h2>
                                    {assetLink}
                                </div>
                            </div>   

                    </div>
                        // end of internal return
                    );
                        // end of mapping team members
                  })}

           </div>

        </div>

    </section>
     // end of main return
  )
}
else{
    return (
      <section className={stylesW.walkingTours}>
        <div className={styles.layoutBody}>

          <div className={stylesW.walkingToursWrapper}>
                   
               {tours.map((tour,i) => {
                       
               return(
                   <div key={i} className={stylesW.walkingTourItem}>
                       {tour.photo && tour.photo.asset && (   
                           <GatsbyImage alt={tour.title} image={tour.photo.asset.gatsbyImageData}></GatsbyImage>)} 
                           
                           <div className={stylesW.walkingTourContent}>
                             <div id={id2} className={stylesW.walkingTourText}>
                                <h2>{tour.title}</h2>
                             </div>
                           </div>   

                   </div>
                       // end of internal return
                   );
                       // end of mapping team members
                 })}

          </div>

       </div>

   </section>
    // end of main return

    )
}

}

export default walkingTours