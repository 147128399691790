import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import * as styles from '../../../sass/layout.module.scss'
import "../../../../src/styles/global.scss"
import * as stylesS from '../splitImageRight/splitImageRight.module.scss'


const splitTextLeft = ({block, raw}) => {
  const {header,subcontentleft,leftcta,image, ctaLink} = block
  var special = "";
  if(header === "Museum Shop"){
       special = "special-shadow"
  }
  return (
    <section className={stylesS.splitImageRight}>
      <section className={stylesS.splitTextLeft}>
         <div className={styles.layoutBody}>
            
         
          <div className={stylesS.splitWrapperFlipped}>

            <div className={stylesS.splitLeftWrap}>
                <h1>{header}</h1>
                {subcontentleft.map(val => {
                        
                        var style = val.style;
                        if(style === 'h1'){
                        return  <h1 className={stylesS.splitLeftText}> {val.children.map((x,j) => {
                                return<span key={j}>{x.text}</span>; })} </h1>;
                        } else  if(style === 'h2'){  
                            return  <h2 className={stylesS.splitLeftText}> {val.children.map((x,j) => {
                                return<span key={j}>{x.text}</span>; })} </h2>;

                        } else  if(style === 'h3'){ 
                            return  <h3 className={stylesS.splitLeftText}> {val.children.map((x,j) => {
                                return<span key={j}>{x.text}</span>; })} </h3>;

                        } else  if(style === 'h4'){ 
                            return  <h4 className={stylesS.splitLeftText}> {val.children.map((x,j) => {
                                return<span key={j}>{x.text}</span>; })} </h4>; 
                        } else  if(style === 'h5'){  

                            return  <h5 className={stylesS.splitLeftText}> {val.children.map((x,j) => {
                                return<span key={j}>{x.text}</span>; })} </h5>;
                        } 
                        else{
                            return  <p className={stylesS.splitLeftText}> {val.children.map((x,j) => {
                                return<span key={j}>{x.text}</span>; })} </p>;
                        }
                        
                        
                        })}
                
                <Link to={ctaLink}> <div className={stylesS.splitCtaOrange}>
                    <p>{leftcta}</p>
                </div></Link>

            </div>
            <div id={special} className={stylesS.splitRight}>
                {image && image.asset && (
                <GatsbyImage 
                    alt={header} 
                    image={image.asset.gatsbyImageData}>
                </GatsbyImage>
                )}
            </div>
           

          </div>

        </div>
        </section>
    </section>
  )
}

export default splitTextLeft