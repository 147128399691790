import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image';
import * as styles from '../../../sass/layout.module.scss'
import * as stylesT from './trisplit.module.scss'



const trisplit = ({block, raw}) => {
  const {header,subheader,leftimage,middleimage,rightimage,leftheader,middleheader,rightheader,leftcontent,middlecontent,rightcontent} = block

  return (
    <section className={stylesT.triSplit}>
        <div className={styles.layoutBody}>
            <h1>{header}</h1>
            <p>{subheader}</p>
            <div className={stylesT.triSplitWrapper}>

               <div className={stylesT.triSplitItem}>
                    {leftimage && leftimage.asset && (   
                    <GatsbyImage  alt={'Photo'} image={leftimage.asset.gatsbyImageData}></GatsbyImage>
                    )}
                    
                    <h2>{leftheader}</h2>
                    <p>{leftcontent}</p>
               </div> 

               <div className={stylesT.triSplitItem}>
                    {middleimage && middleimage.asset && (   
                    <GatsbyImage  alt={'Photo'} image={middleimage.asset.gatsbyImageData}></GatsbyImage>
                    )}
                    
                    <h2>{middleheader}</h2>
                    <p>{middlecontent}</p>
               </div> 

               <div className={stylesT.triSplitItem}>
                    {rightimage && rightimage.asset && (   
                    <GatsbyImage  alt={'Photo'} image={rightimage.asset.gatsbyImageData}></GatsbyImage>
                    )}
                    
                    <h2>{rightheader}</h2>
                    <p>{rightcontent}</p>
               </div> 

            </div>  
       </div>
    </section>
  )
}

export default trisplit