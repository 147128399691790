import React from 'react';
import * as styles from './splitBlogContent.module.scss'
import PortableText from 'react-portable-text';


export default function splitBlogContent({block, raw }) {
	const {contentDir, contentSize, _rawContentLeft, _rawContentRight} = block
	var contentWidth = {}
	var contentLoc = {}
	if(contentSize === 'small'){
		contentWidth = {maxWidth: "600px"}
	}
	else if(contentSize === 'medium'){
		contentWidth = {maxWidth: "800px"}
	}
	else{
		contentWidth = {maxWidth: "1170px"}
	}
	if(contentDir === 'center'){
		contentLoc = {textAlign: "center"}
	}
	else if(contentDir === 'right'){
		contentLoc = {textAlign: "right"}
	}
	else{
		contentLoc = {textAlign: "left"}
	}

	return (
		<div className={styles.textblockWrap} >
			<div className={styles.layoutBlog} style={contentWidth}>
				<div className={styles.contentWrap}  style={contentLoc}>
					{(_rawContentLeft) && (
						<PortableText content={_rawContentLeft}/>
					)}
				</div>
			</div>

			<div className={styles.layoutBlog} style={contentWidth}>
				<div className={styles.contentWrap}  style={contentLoc}>
					{(_rawContentRight) && (
						<PortableText content={_rawContentRight}/>
					)}
				</div>
			</div>
		</div>
	)
}
