
import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import PageBuilder from '../components/pageBuilder'
import "../../src/styles/contact.scss"
import { Helmet } from 'react-helmet';




export const query = graphql`
  query PageTemplateQuery($id: String!) {
    page: sanityPage(id: {eq: $id}) {
      id
      ...PageBuilder
      pageName
      slug {
        current
      }
    }
  }
`

const PageTemplate = props => {
  const {data} = props
  const page = data && data.page
  const {pageBuilder, _rawPageBuilder} = page
if(page.pageName === 'About'){
  return (
    <Layout>
       <Helmet>
        <meta charSet="utf-8" />
        <title>{page.pageName}-History Cherokee</title>
        <meta name="referrer" content="origin" />
       <link rel="stylesheet" type="text/css" href="https://cloud.typography.com/712794/7109232/css/fonts.css" />
      </Helmet>   
      <div class="container-full">
      <PageBuilder pageBuilder={pageBuilder} _rawPageBuilder={_rawPageBuilder} />
      </div>
    </Layout>
  )
} 
if(page.pageName === 'Home Page'){
  return (
    <Layout>
       <Helmet>
      <meta charSet="utf-8" />
      <title>History Cherokee</title>
      <meta name="referrer" content="origin" />
     <link rel="stylesheet" type="text/css" href="https://cloud.typography.com/712794/7109232/css/fonts.css" />
       </Helmet>   
    <div class="container-full">
    <PageBuilder pageBuilder={pageBuilder} _rawPageBuilder={_rawPageBuilder} />
    </div>
  </Layout>
  )
} 
if(page.pageName === 'Support'){
  return (
   
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{page.pageName}-History Cherokee</title>
        <meta name="referrer" content="origin" />
       <link rel="stylesheet" type="text/css" href="https://cloud.typography.com/712794/7109232/css/fonts.css" />
      </Helmet>   
      <div class="container-full">
      <PageBuilder pageBuilder={pageBuilder} _rawPageBuilder={_rawPageBuilder} />
      </div>
    </Layout>
  )
}
else{
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{page.pageName}-History Cherokee</title>
        <meta name="referrer" content="origin" />
       <link rel="stylesheet" type="text/css" href="https://cloud.typography.com/712794/7109232/css/fonts.css" />
      </Helmet>   
      <div class="container-full">
      <PageBuilder pageBuilder={pageBuilder} _rawPageBuilder={_rawPageBuilder} />
      </div>
    </Layout>
  )
  }

}

export default PageTemplate