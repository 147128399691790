import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image';
import * as styles from '../../../sass/layout.module.scss'
import * as stylesB from '../splitWithBackground/splitWithBackground.module.scss'
import "../../../../src/styles/global.scss"



const splitWithBackground = ({block, raw}) => {
const {optionalheader,header,subcontentb,image,imagecheck,backgroundcolor,optionalcta,ctaurl,ctaLabel} = block
  
  const backgroundSplit ='background-' + backgroundcolor ;
   const cta = 'background-cta-' +optionalcta;
   const quicklink = 'link-'+optionalheader
      
   //if image is set to be on right

  if(imagecheck === 'right'){
    return(
    <section class={backgroundSplit} >
    <div className={styles.layoutBody} id={quicklink}>
    
     <div className={stylesB.splitBackgroundWrap}>
   
    
     <div className={stylesB.splitBackgroundContentWrap}>
        <div className={stylesB.splitBackgroundContent}>
           <h3>{optionalheader}</h3>
           <h1>{header}</h1>
           {subcontentb.map(val => {

                        var style = val.style;
           
                        if(style === 'h1'){
                        return  <h1 className={stylesB.splitLeftText}> {val.children.map((x,j) => {
                                return<span key={j}>{x.text}</span>; })} </h1>;
                        } else  if(style === 'h2'){  
                            return  <h2 className={stylesB.splitLeftText}> {val.children.map((x,j) => {
                                return<span key={j}>{x.text}</span>; })} </h2>;

                        } else  if(style === 'h3'){ 
                            return  <h3 className={stylesB.splitLeftText}> {val.children.map((x,j) => {
                                return<span key={j}>{x.text}</span>; })} </h3>;

                        } else  if(style === 'h4'){ 
                            return  <h4 className={stylesB.splitLeftText}> {val.children.map((x,j) => {
                                return<span key={j}>{x.text}</span>; })} </h4>; 
                        } else  if(style === 'h5'){  

                            return  <h5 className={stylesB.splitLeftText}> {val.children.map((x,j) => {
                                return<span key={j}>{x.text}</span>; })} </h5>;
                        } 
                        else{
                            return  <p className={stylesB.splitLeftText}> {val.children.map((x,j) => {
                                return<span key={j}>{x.text}</span>; })} </p>;
                        }
                        
                        
                        })}
        
            <div className={cta} id="backgroundCta">
                <a class="background-cta-text" href={ctaurl}><p class="background-cta-text">{ctaLabel}</p></a>
            </div>
       </div>
       </div>
       
       <div className={stylesB.splitBackgroundImage}>
           {image && image.asset && (
           <GatsbyImage 
               alt={header} 
               image={image.asset.gatsbyImageData}>
           </GatsbyImage>
           )}
       </div>

   </div>

  </div>


</section>

    )
        
}
//if image is set to be on left
else if(imagecheck === 'Left'){

return(
    <section className={backgroundSplit} id="splitWithBackground">
        <div className={styles.layoutBody} id={quicklink}>
    
         <div className={stylesB.splitBackgroundWrapFlipped}>
    
         <div className={stylesB.splitBackgroundContentWrap}>
            <div className={stylesB.splitBackgroundContent}>
                <h3>{optionalheader}</h3>
                <h1>{header}</h1>
                {subcontentb.map((val,i) => {
                        
                        var style = val.style;
                        if(style === 'h1'){
                        return  <h1 key={i} className={stylesB.splitLeftText}> {val.children.map((x,j) => {
                                return<span key={j}>{x.text}</span>; })} </h1>;
                        } else  if(style === 'h2'){  
                            return  <h2 key={i} className={stylesB.splitLeftText}> {val.children.map((x,j) => {
                                return<span key={j}>{x.text}</span>; })} </h2>;

                        } else  if(style === 'h3'){ 
                            return  <h3 key={i} className={stylesB.splitLeftText}> {val.children.map((x,j) => {
                                return<span key={j}>{x.text}</span>; })} </h3>;

                        } else  if(style === 'h4'){ 
                            return  <h4 key={i} className={stylesB.splitLeftText}> {val.children.map((x,j) => {
                                return<span key={j}>{x.text}</span>; })} </h4>; 
                        } else  if(style === 'h5'){  

                            return  <h5 key={i} className={stylesB.splitLeftText}> {val.children.map((x,j) => {
                                return<span key={j}>{x.text}</span>; })} </h5>;
                        } 
                        else{
                            return  <p key={i} className={stylesB.splitLeftText}> {val.children.map((x,j) => {
                                return<span key={j}>{x.text}</span>; })} </p>;
                        }
                        
                        
                        })}
        
                <div className={cta} id="backgroundCta">
                    <a class="background-cta-text" href={ctaurl}><p class="background-cta-text">{ctaLabel}</p></a>
                </div>
           </div>
        </div>
       
            <div className={stylesB.splitBackgroundImage}>
                {image && image.asset && (
                <GatsbyImage 
                    alt={header} 
                    image={image.asset.gatsbyImageData}>
                </GatsbyImage>
                )}
            </div>

        </div>

    </div>

</section>
)


}
  //if no image directions picked dont show
  else{
    return( <div>{header}</div> )
    
    }
}


export default splitWithBackground

