// extracted by mini-css-extract-plugin
export var contentBlockWrap = "splitImageRight-module--content-block-wrap--e715b";
export var splitCta = "splitImageRight-module--split-cta--e80f3";
export var splitCtaOrange = "splitImageRight-module--split-cta-orange--9bef9";
export var splitCtaText = "splitImageRight-module--split-cta-text--42aee";
export var splitImageRight = "splitImageRight-module--split-image-right--819f0";
export var splitLeft = "splitImageRight-module--split-left--8989f";
export var splitLeftContent = "splitImageRight-module--split-left-content--ac683";
export var splitLeftText = "splitImageRight-module--split-left-text--3dde3";
export var splitLeftWrap = "splitImageRight-module--split-left-wrap--8d70a";
export var splitRight = "splitImageRight-module--split-right--f80d8";
export var splitTextLeft = "splitImageRight-module--split-text-left--25770";
export var splitWrapper = "splitImageRight-module--split-wrapper--186f5";
export var splitWrapperFlipped = "splitImageRight-module--split-wrapper-flipped--66905";