import React from 'react'
import { Link } from 'gatsby';
import * as styles from '../../../sass/layout.module.scss'
import * as stylesD from './disclaimer.module.scss'

const disclaimer = ({block, raw}) => {
  const {text, ctaLabel, ctaLink} = block

  return (
    <section>
        <div className={stylesD.disclaimerWrapper}>
            <div className={styles.layoutBody}>
                <p className={stylesD.disclaimerText}>{text}<Link to={ctaLink}><span>{ctaLabel}</span></Link></p>
            </div>
       </div>
    </section>
  )
}

export default disclaimer