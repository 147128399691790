import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image';
import * as styles from '../../../sass/layout.module.scss'
import * as stylesE from './events.module.scss'
import { Link } from 'gatsby';


const events = ({block, raw}) => {
  const {header,event} = block
  return (
    <section className={stylesE.events}>
         <div className={styles.layoutBody}>
            
         <h1>{header}</h1>

           <div className={stylesE.eventsEventWrapper}>
                    
                {event.map(event => {
                        
                return(
                    <div className={stylesE.eventItem}>
                        <div className={stylesE.eventImage}>
                            <div>
                                {event.image && event.image.asset && (   
                                <GatsbyImage alt={event.header} image={event.image.asset.gatsbyImageData}></GatsbyImage>)} 
                            </div> 
                        </div>   
                            
                        <div className={stylesE.eventContent}>
                            <h1>{event.header}</h1>
                            <p>{event.content}</p>
                                <Link to={event.link}> <div className={stylesE.eventCta}>
                                     <p className={stylesE.eventCtaText}>{event.ctaLabel}</p>
                                </div></Link>
                         </div>   

                    </div>
                        // end of internal return
                    );
                        // end of mapping events
                  })}

           </div>

        </div>

    </section>
     // end of main return
  )

}

export default events