import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import * as styles from '../../../sass/layout.module.scss'
import "../../../../src/styles/global.scss"
import * as stylesS from './splitImageRight.module.scss'


const splitImageRight = ({block, raw}) => {
  const {header,subcontents,leftcta,leftheading,image,content,ctaLink} = block
  var special = "";
  if(header === "Museum Shop"){
       special = "special-shadow"
  }
  if(content !== null){
  return (
    <section className={stylesS.splitImageRight}>
         <div className={styles.layoutBody}>
            
         
          <div className={stylesS.splitWrapper}>

            <div className={stylesS.splitLeft}>
                <h1>{header}</h1>
                {subcontents.map(val => {
                        
                        var style = val.style;
                        
                        if(style === 'h1'){
                        return  <h1 className={stylesS.splitLeftText}> {val.children.map((x,j) => {
                                return<span key={j}>{x.text}</span>; })} </h1>;
                        } else  if(style === 'h2'){  
                            return  <h2 className={stylesS.splitLeftText}> {val.children.map((x,j) => {
                                return<span key={j}>{x.text}</span>; })} </h2>;

                        } else  if(style === 'h3'){ 
                            return  <h3 className={stylesS.splitLeftText}> {val.children.map((x,j) => {
                                return<span key={j}>{x.text}</span>; })} </h3>;

                        } else  if(style === 'h4'){ 
                            return  <h4 className={stylesS.splitLeftText}> {val.children.map((x,j) => {
                                return<span key={j}>{x.text}</span>; })} </h4>; 
                        } else  if(style === 'h5'){  

                            return  <h5 className={stylesS.splitLeftText}> {val.children.map((x,j) => {
                                return<span key={j}>{x.text}</span>; })} </h5>;
                        } 
                        else{
                            return  <p className={stylesS.splitLeftText}> {val.children.map((x,j) => {
                                if(x.text.includes("call")){
                                return<a href="tel:7703543288"><span key={j}>{x.text}</span></a>; 
                                    }
                                else{
                                return<span key={j}>{x.text}</span>; 
                                 }
                                })} </p>;
                           
                        }
                        
                        
                        })}
                
                <Link to={ctaLink}> <div className={stylesS.splitCta} id={header}>
                    <p className={stylesS.splitCtaText}>{leftcta}</p>
                </div></Link>

                 <div className={stylesS.splitLeftContent}>    
                    <h3>{leftheading}</h3>
                    
                
                    {content.map(val => {
                        
                        var style = val.style;
                        if(style === 'h1'){
                        return  <h1 className={stylesS.splitLeftText}> {val.children.map((x,j) => {
                                return<span key={j}>{x.text}</span>; })} </h1>;
                        } else  if(style === 'h2'){  
                            return  <h2 className={stylesS.splitLeftText}> {val.children.map((x,j) => {
                                return<span key={j}>{x.text}</span>; })} </h2>;

                        } else  if(style === 'h3'){ 
                            return  <h3 className={stylesS.splitLeftText}> {val.children.map((x,j) => {
                                return<span key={j}>{x.text}</span>; })} </h3>;

                        } else  if(style === 'h4'){ 
                            return  <h4 className={stylesS.splitLeftText}> {val.children.map((x,j) => {
                                return<span key={j}>{x.text}</span>; })} </h4>; 
                        } else  if(style === 'h5'){  

                            return  <h5 className={stylesS.splitLeftText}> {val.children.map((x,j) => {
                                return<span key={j}>{x.text}</span>; })} </h5>;
                        } 
                        else{
                            return  <p className={stylesS.splitLeftText}> {val.children.map((x,j) => {
                                if(x.text.includes("call")){
                                return<a href="tel:7703543288"><span key={j}>{x.text}</span></a>; 
                                    }
                                else{
                                return<span key={j}>{x.text}</span>; 
                                 }
                                })} </p>;
                        }
                        
                        
                        })}
        
                </div>

            </div>
            <div id={special} className={stylesS.splitRight}>
                {image && image.asset && (
                <GatsbyImage 
                    alt={header} 
                    image={image.asset.gatsbyImageData}>
                </GatsbyImage>
                )}
            </div>
           

          </div>

        </div>
    </section>
  )
}
else{
    return (
        <section className={stylesS.splitImageRight}>
             <div className={styles.layoutBody}>
                
             
              <div className={stylesS.splitWrapper}>
    
                <div className={stylesS.splitLeft}>
                    <h1>{header}</h1>
                    {subcontents.map(val => {
                        
                        var style = val.style;
                        if(style === 'h1'){
                        return  <h1 className={stylesS.splitLeftText}> {val.children.map((x,j) => {
                                return<span key={j}>{x.text}</span>; })} </h1>;
                        } else  if(style === 'h2'){  
                            return  <h2 className={stylesS.splitLeftText}> {val.children.map((x,j) => {
                                return<span key={j}>{x.text}</span>; })} </h2>;

                        } else  if(style === 'h3'){ 
                            return  <h3 className={stylesS.splitLeftText}> {val.children.map((x,j) => {
                                return<span key={j}>{x.text}</span>; })} </h3>;

                        } else  if(style === 'h4'){ 
                            return  <h4 className={stylesS.splitLeftText}> {val.children.map((x,j) => {
                                return<span key={j}>{x.text}</span>; })} </h4>; 
                        } else  if(style === 'h5'){  

                            return  <h5 className={stylesS.splitLeftText}> {val.children.map((x,j) => {
                                return<span key={j}>{x.text}</span>; })} </h5>;
                        } 
                        else{
                            return  <p className={stylesS.splitLeftText}> {val.children.map((x,j) => {
                                if(x.text.includes("call")){
                                return<a href="tel:7703543288"><span key={j}>{x.text}</span></a>; 
                                    }
                                else{
                                return<span key={j}>{x.text}</span>; 
                                 }
                                })} </p>;
                        }
                        
                        
                        })}
                    
                    <Link to={ctaLink}> <div className={stylesS.splitCta}>
                    <p className={stylesS.splitCtaText}>{leftcta}</p>
                    </div></Link>
    
                     <div className={stylesS.splitLeftContent}>    
                        <h3>{leftheading}</h3>
            
                    </div>
    
                </div>
                <div id={special} className={stylesS.splitRight}>
                    {image && image.asset && (
                    <GatsbyImage 
                        alt={header} 
                        image={image.asset.gatsbyImageData}>
                    </GatsbyImage>
                    )}
                </div>
               
    
              </div>
    
            </div>
        </section>
      )
    
}
 
}

export default splitImageRight