import React from 'react'
import * as stylesH from './heroCarousel.module.scss'
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import {Link} from "gatsby";
import { getSrc } from "gatsby-plugin-image";

const heroCarousel = ({block, raw}) => {
	// const {homeheader,subcontent,leftcta,rightcta,hero,leftLink,rightLink} = block
	const {
		homeheader1,subcontent1,leftcta1,rightcta1,hero1,leftLink1,rightLink1,
		homeheader2,subcontent2,leftcta2,rightcta2,hero2,leftLink2,rightLink2,
		homeheader3,subcontent3,leftcta3,rightcta3,hero3,leftLink3,rightLink3,
	} = block

	const slideImages = [
		{
			url: getSrc(hero1.asset.gatsbyImageData),
			header: homeheader1,
			subcontent: subcontent1,
			leftcta: leftcta1,
			rightcta: rightcta1,
			leftLink: leftLink1,
			rightLink: rightLink1,
		},
		{
			url: getSrc(hero2.asset.gatsbyImageData),
			header: homeheader2,
			subcontent: subcontent2,
			leftcta: leftcta2,
			rightcta: rightcta2,
			leftLink: leftLink2,
			rightLink: rightLink2,
		},
		{
			url: getSrc(hero3.asset.gatsbyImageData),
			header: homeheader3,
			subcontent: subcontent3,
			leftcta: leftcta3,
			rightcta: rightcta3,
			leftLink: leftLink3,
			rightLink: rightLink3,
		},
	];

	return (
		<section className={stylesH.heroCarousel}>

				<Slide>
					{slideImages.map((slideImage, index)=> (
						<div key={index}>
							<div className={ stylesH.heroCarouselBg } style={{ 'backgroundImage': `url(${slideImage.url})` }}>
								<div className={stylesH.heroCarouselContent}>
									<h1>{slideImage.header}</h1>
									<p>{slideImage.subcontent}</p>
									<div className={stylesH.heroCarouselButtons}>
										<Link to={slideImage.leftLink}> <div className={stylesH.heroCarouselLeftcta}><p className={stylesH.heroCarouselLeftctaText}>{slideImage.leftcta}</p></div> </Link>
										<Link to={slideImage.rightLink}> <div className={stylesH.heroCarouselRightcta}><p className={stylesH.heroCarouselRightctaText}>{slideImage.rightcta}</p></div> </Link>
									</div>
								</div>
							</div>
						</div>
					))}
				</Slide>
		</section>
	)
}
export default heroCarousel
