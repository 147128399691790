import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image';
import * as styles from '../../../sass/layout.module.scss'
import * as stylesT from '../ourTeam/ourTeam.module.scss'


const ourTeam = ({block, raw}) => {
  const {header,teammembers} = block

  return (
    <section className={stylesT.ourTeam}>
         <div className={styles.layoutBody}>
            
         <h1>{header}</h1>
            <div className={stylesT.ourTeamWrapper}>

            {teammembers.map(teamMember => {
                var str = teamMember.phone;
                const number = str.match(/.{1,3}/g);
                const numberL =  str.match(/.{1,1}/g);
                const phone = "tel:"+teamMember.phone;
                const email = "mailto:"+teamMember.email;
          return( 
           <div className={stylesT.ourTeamPerson}>
           
                <div >
                {teamMember.photo && teamMember.photo.asset && (   
                        <GatsbyImage 
                    alt={'team Photo'} 
                    image={teamMember.photo.asset.gatsbyImageData}></GatsbyImage>
                )}
                </div>

             <h2>{teamMember.firstName} {teamMember.lastName}</h2>
             <h3>{teamMember.role}</h3>
             <p>{teamMember.info}</p>
             <h4><a href={phone}>{number[0]}-{number[1]}-{number[2]}{numberL[9]} Extension {numberL[13]} {teamMember.exstension}</a></h4>
             <h4><a href={email}>{teamMember.email}</a></h4>
           </div>
         );
                // end of mapping team members
            })}



          </div>

        </div>

    </section>
  )
}

export default ourTeam