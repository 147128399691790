  
import React from 'react'
import * as stylesS from '../main/splitImageRight/splitImageRight.module.scss'
import * as styles from '../../sass/layout.module.scss'


const contentBlock = ({block, raw}) => {
  const {content} = block

  return (
    <section>
       <div className={stylesS.contentBlockWrap}>
         <div className={styles.layoutBody}>
          
        {content.map(val => {
                        
                        var style = val.style;
                        if(style === 'h1'){
                          return  <h1 className={stylesS.splitLeftText}> {val.children.map((x,j) => {
                                  return<span key={j}>{x.text}</span>; })} </h1>;
                          } else  if(style === 'h2'){  
                              return  <h2 className={stylesS.splitLeftText}> {val.children.map((x,j) => {
                                  return<span key={j}>{x.text}</span>; })} </h2>;

                          } else  if(style === 'h3'){ 
                              return  <h3 className={stylesS.splitLeftText}> {val.children.map((x,j) => {
                                  return<span key={j}>{x.text}</span>; })} </h3>;

                          } else  if(style === 'h4'){ 
                              return  <h4 className={stylesS.splitLeftText}> {val.children.map((x,j) => {
                                  return<span key={j}>{x.text}</span>; })} </h4>; 
                          } else  if(style === 'h5'){  

                              return  <h5 className={stylesS.splitLeftText}> {val.children.map((x,j) => {
                                  return<span key={j}>{x.text}</span>; })} </h5>;
                          } 
                          else{
                              return  <p className={stylesS.splitLeftText}> {val.children.map((x,j) => {
                                  return<span key={j}>{x.text}</span>; })} </p>;
                          }
                          
                          
                          })}
            </div>
        </div>
    </section>
  )
}

export default contentBlock