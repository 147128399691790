import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import * as styles from '../../../sass/layout.module.scss'
import * as stylesT from '../topSplit/topSplit.module.scss'


const topSplit = ({block, raw}) => {
  const {leftheader,leftcontent,leftlink,leftlabel,leftimage,rightheader,rightcontent,rightlink,rightlabel,rightimage} = block
  return (
    <section className={stylesT.topSplitCta}>
        <div className={styles.layoutBody}>


        <div className={stylesT.topSplitGrid}>

            <div className={stylesT.topGridItem}>
                <div className={stylesT.topGridImage}>
                {leftimage && leftimage.asset && (
                    <GatsbyImage 
                    alt={leftheader} 
                    image={leftimage.asset.gatsbyImageData}></GatsbyImage>
                )}
                </div>
                <div className={stylesT.topGridContent}>
                    <h1>{leftheader}</h1>
                    <p>{leftcontent}</p>
                    <Link to={leftlink}><div className={stylesT.topGridCta}>
                        <p>{leftlabel}</p>
                    </div></Link>
                </div>  
            </div>

            <div className={stylesT.topGridItem}>
                <div className={stylesT.topGridImage}>
                {rightimage && rightimage.asset && (
                    <GatsbyImage 
                    alt={leftheader} 
                    image={rightimage.asset.gatsbyImageData}></GatsbyImage>
                )}
                </div>
                <div className={stylesT.topGridContent}>
                    <h1>{rightheader}</h1>
                    <p>{rightcontent}</p>
                    <Link to={rightlink}><div className={stylesT.topGridCtaRight}>
                        <p>{rightlabel}</p>
                    </div></Link>   
                </div>
                
            </div>

        </div>
      </div>
    </section>
  )
}

export default topSplit