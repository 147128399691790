import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image';
import * as styles from '../../../sass/layout.module.scss'
import * as stylesV from './videoWithImage.module.scss'
import "../../../../src/styles/global.scss"

const videoWithImage = ({block, raw}) => {
  const {hero,header,source} = block

  return (
    <section id="campaign-area" onClick={popVideo} role="textbox" tabIndex={0} onKeyDown={popVideo} className={stylesV.videoWithImage}>
       <div className={styles.layoutBody}>
        
       
       <div className={stylesV.videoWrapper}>

            <div className={stylesV.videoImage} id="pop-trigger" >
                {hero && hero.asset && (<GatsbyImage  alt={header}  image={hero.asset.gatsbyImageData}></GatsbyImage> )}
            </div>

            <div className={stylesV.videoContentArea}>
                <h1>{header}</h1>   
            </div>

        </div>

           

    <div id="pop-video" className="video-wrapper">

    <iframe src={source} title="YouTube video player" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen
        />

     </div>
        
        
       </div>


    </section>
  )
}

export default videoWithImage

//pop out video
function popVideo(event) {
    var video = document.getElementById("pop-video");
    if (video.style.opacity === "1") {
        video.style.opacity="0";
        video.style.display="none";

    } 
    else {
        video.style.opacity="1";
        video.style.display="flex";
    
    }
  
  }