  
import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image';
import * as styles from '../../sass/layout.module.scss'



const CompanyLogos = ({block, raw}) => {
  const {name, logo} = block
  return (
    <div className={styles.layoutBody}>
    <section >

      <div>
        <h1>
          {name}
          </h1>
          
          {logo && logo.asset && (
         <GatsbyImage 
         alt={name} 
         image={logo.asset.gatsbyImageData}></GatsbyImage>
        )}
        test
        
      </div>
    </section>
    </div>
  )
}

export default CompanyLogos
