import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image';
import * as styles from '../../../sass/layout.module.scss'
import * as stylesT from './threeBlockText.module.scss'
import { Link } from 'gatsby';

const threeBlockText = ({block, raw}) => {
  const {header,subcontent,leftheader,leftcontent,leftcta,leftimage,rightheader,rightcontent,rightcta,rightimage,middleheader,middlecontent,middlecta,middleimage,leftLink,rightLink,middleLink} = block


  return (
    <section className={stylesT.threeBlockText}>
      <div className={styles.layoutBody}>
         <div className={stylesT.threeBlockTopContent}>
            <h1>{header}</h1>
            <p>{subcontent}</p>
        </div>

        <div className={stylesT.threeBlockGrid}>

            <div className={stylesT.threeBlockGridItem}>
                
                <div className={stylesT.threeBlockGridImage}>
                    {leftimage && leftimage.asset && (
                        <GatsbyImage 
                            alt={header}
                            image={leftimage.asset.gatsbyImageData}>
                        </GatsbyImage>
                        )}
                </div> 
                
                <h1>{leftheader}</h1>
                <div className={stylesT.threeContentWrap}>
                    <p>{leftcontent}
                        <Link to={leftLink}> 
                            <h5 className={stylesT.threeCtaButtonText}>{leftcta}</h5>
                        </Link>
                    </p>
                </div>


            </div>

            <div className={stylesT.threeBlockGridItem}>
                
                <div className={stylesT.threeBlockGridImage}>
                    {middleimage && middleimage.asset && (
                        <GatsbyImage 
                            alt={header}
                            className={stylesT.threeBlockGridImage} 
                            image={middleimage.asset.gatsbyImageData}>
                        </GatsbyImage>
                        )}
                </div>

                <h1>{middleheader}</h1>
                <div className={stylesT.threeContentWrap}>
                    <p>{middlecontent}
                        <Link to={middleLink}> 
                            <h5 className={stylesT.threeCtaButtonText}>{middlecta}</h5>
                        </Link>
                    </p>
                </div>

            </div>

            <div className={stylesT.threeBlockGridItem}>
        
                <div className={stylesT.threeBlockGridImage}>
                    {rightimage && rightimage.asset && (
                        <GatsbyImage 
                            alt={header} 
                            className={stylesT.threeBlockGridImage}
                            image={rightimage.asset.gatsbyImageData}>
                        </GatsbyImage>
                        )}
                </div>

                <h1>{rightheader}</h1>
                <div className={stylesT.threeContentWrap}>
                    <p>{rightcontent}
                        <Link to={rightLink}> 
                            <h5 className={stylesT.threeCtaButtonText}>{rightcta}</h5>
                        </Link>
                    </p>
                </div>

            </div>

         </div>
        </div>
    </section>
  )
}

export default threeBlockText