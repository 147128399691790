  
import React from 'react'
import * as styles from '../sass/layout.module.scss'
import * as stylesB from '../sass/board.module.scss'

export default function Board() {
    return (
    <section className={stylesB.boardContainer}>
        <div className={styles.layoutBody}>
            <h1>Our Board</h1>
            {/* CHS Board internal*/}
            <div className={stylesB.boardMainWrap}>
                
                 <div className={stylesB.boardMainLeft}>
                 
                    <div className={stylesB.boardMainItem}>
                        <h2>Joe Sellers</h2>
                        <p>Vice President</p>
                    </div>

                 </div>

                 <div className={stylesB.boardMainMiddle}>
                 <div className={stylesB.boardMainItem}>
                        <h2>Jeff Brown</h2>
                        <p>President</p>
                    </div>
                    <div className={stylesB.boardMainItem}>
                        <h2>Cory Wilson</h2>
                        <p>Treasurer</p>
                    </div>
                    <div className={stylesB.boardMainItem}>
                        <h2>Jane Shelnutt</h2>
                        <p>Parlimentarian</p>
                    </div>

                 </div>

                 <div className={stylesB.boardMainRight}>
                 <div className={stylesB.boardMainItem}>
                        <h2>Carla Barnes</h2>
                        <p>Secretary</p>
                    </div>

                 </div>

            </div>

              {/* CHS Board of directors */}
            <h1>Board of Directors</h1>
            <div className={stylesB.boardDirWrap}>

                <div className={stylesB.boardDirLeft}>
                    
                    <div className={stylesB.boardDirItem}>
                        <h1>Kyle Bennett</h1>
                    </div>
                     
                    <div className={stylesB.boardDirItem}>
                        <h1>Mary Ann Hindman</h1>
                    </div>
                     
                    <div className={stylesB.boardDirItem}>
                        <h1>Jon McDaniel</h1>
                    </div>
                     
                    <div className={stylesB.boardDirItem}>
                        <h1>Mark Moore</h1>
                    </div>

                </div>
                    
                <div className={stylesB.boardDirMiddle}>
                     
                    <div className={stylesB.boardDirItem}>
                        <h1>Michael Caldwell</h1>
                    </div>
                     
                    <div className={stylesB.boardDirItem}>
                        <h1>Bob Husley</h1>
                    </div>
                     
                    <div className={stylesB.boardDirItem}>
                        <h1>Sally McPherson</h1>
                    </div>
                     
                    <div className={stylesB.boardDirItem}>
                        <h1>Spencer Nix</h1>
                    </div>

                </div>
                
                <div className={stylesB.boardDirRight}>
                     
                    <div className={stylesB.boardDirItem}>
                        <h1>Chuck Douglas</h1>
                    </div>
                     
                    <div className={stylesB.boardDirItem}>
                        <h1>Rebecca Johnston</h1>
                    </div>
                     
                    <div className={stylesB.boardDirItem}>
                        <h1>Bob Manly</h1>
                    </div>
                     
                    <div className={stylesB.boardDirItem}>
                        <h1>Lynne Worthington</h1>
                    </div>

                </div>

            </div>

        </div>
        </section>
    );
  }