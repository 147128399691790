// extracted by mini-css-extract-plugin
export var boardContainer = "board-module--board-container--408b3";
export var boardDirItem = "board-module--board-dir-item--862ac";
export var boardDirLeft = "board-module--board-dir-left--088b2";
export var boardDirMiddle = "board-module--board-dir-middle--e8722";
export var boardDirRight = "board-module--board-dir-right--0803e";
export var boardDirWrap = "board-module--board-dir-wrap--e0ff9";
export var boardMainItem = "board-module--board-main-item--883db";
export var boardMainLeft = "board-module--board-main-left--6c90b";
export var boardMainMiddle = "board-module--board-main-middle--12da6";
export var boardMainRight = "board-module--board-main-right--275d0";
export var boardMainWrap = "board-module--board-main-wrap--53b4d";