import React from 'react'
import * as styles from '../../../sass/layout.module.scss'
import * as stylesR from './resources.module.scss'
import { GatsbyImage } from 'gatsby-plugin-image';



const resourceArea= ({block, raw}) => {
  const {leftimage, leftheader, leftcontent,leftfile, rightimage, rightheader, rightcontent,rightfile, bleftimage, bleftheader, bleftcontent,bleftfile,brightimage, brightheader, brightcontent,archives} = block
  let counter = 0;
  let leftFile = ''
    if(leftfile !==null){
        leftFile = leftfile.asset.url
    }
  let bleftFile = ''
    if(bleftfile !==null){
        bleftFile = bleftfile.asset.url
    }
  let rightFile = ''
    if(rightfile !==null){
        rightFile = rightfile.asset.url
    }    
  if(archives.length > 1){
  return (
    <section >
        <div className={styles.layoutBody}>
            <div className={stylesR.resourcesWrap}>

                <div className={stylesR.resourcesItem}>
                
                    <div className={stylesR.resourcesItemImage}>
                        <a href={`${leftFile}?dl=`}>
                            {leftimage && (
                            <GatsbyImage alt={leftheader}  image={leftimage.asset.gatsbyImageData}></GatsbyImage>
                            )}
                            </a>
                        </div> 
                        <a href={`${leftFile}?dl=`}>
                        <div className={stylesR.resourcesItemContent}>
                            <h1>{leftheader}</h1>
                            <p>{leftcontent}</p>
                        </div>
                        </a>   
                    
                    </div>
                <div className={stylesR.resourcesItem}>
                    <div className={stylesR.resourcesItemImage}>
                        <a href={`${rightFile}?dl=`}>
                            {rightimage && (
                            <GatsbyImage alt={rightheader}  image={rightimage.asset.gatsbyImageData}></GatsbyImage>
                            )}
                        </a>
                     </div>    
                     <a href={`${rightFile}?dl=`}>
                        <div className={stylesR.resourcesItemContent}>
                            <h1>{rightheader}</h1>
                            <p>{rightcontent}</p>
                        </div>    
                     </a>
                </div>
                <div className={stylesR.resourcesItem}>
                    <div className={stylesR.resourcesItemImage}>
                        <a href={`${bleftFile}?dl=`}>
                        {bleftimage && (
                        <GatsbyImage alt={bleftheader}  image={bleftimage.asset.gatsbyImageData}></GatsbyImage>
                        )}
                        </a>
                     </div>
                     <a href={`${bleftFile}?dl=`}>
                        <div className={stylesR.resourcesItemContent}>
                            <h1>{bleftheader}</h1>
                            <p>{bleftcontent}</p>
                        </div>
                     </a>        
                </div>
                <div className={stylesR.resourcesItem}>
                    <div className={stylesR.resourcesItemImage}>
                        {brightimage  && (
                        <GatsbyImage alt={brightheader}  image={brightimage.asset.gatsbyImageData}></GatsbyImage>
                        )}
                     </div>    
                     <div className={stylesR.resourcesItemContent}>
                        <h1>{brightheader}</h1>
                        <p>{brightcontent}</p>
                            
                            <div className={stylesR.resourcesArchiveList}>
                            <h2>Archive:</h2>
                                
                                {archives.map((archive,i) => {
                                    
                                counter ++;
                                    return(
                                        <div key={i}>
                                            <div className={stylesR.resourcesArchiveItem}>
                                                 <a href={`${archive.file.asset.url}?dl=`}>{counter}</a>
                                            </div>   
                                        </div>
                                            // end of internal return
                                        );
                                            // end of mapping archives
                                    })}      
                                           
                             </div>    

                     </div>    
                </div>
                
            </div>
       </div>
    </section>
  )
 }
else{
    return(
        <section >
        <div className={styles.layoutBody}>
            <div className={stylesR.resourcesWrap}>

                <div className={stylesR.resourcesItem}>
                
                    <div className={stylesR.resourcesItemImage}>
                        <a href={`${leftFile}?dl=`}>
                            {leftimage && leftimage.asset && (
                            <GatsbyImage alt={leftheader}  image={leftimage.asset.gatsbyImageData}></GatsbyImage>
                            )}
                            </a>
                        </div> 
                        <a href={`${leftFile}?dl=`}>
                        <div className={stylesR.resourcesItemContent}>
                            <h1>{leftheader}</h1>
                            <p>{leftcontent}</p>
                        </div>
                        </a>   
                    
                    </div>
                <div className={stylesR.resourcesItem}>
                    <div className={stylesR.resourcesItemImage}>
                        <a href={`${rightFile}?dl=`}>
                            {rightimage && rightimage.asset && (
                            <GatsbyImage alt={rightheader}  image={rightimage.asset.gatsbyImageData}></GatsbyImage>
                            )}
                        </a>
                     </div>    
                     <a href={`${rightFile}?dl=`}>
                        <div className={stylesR.resourcesItemContent}>
                            <h1>{rightheader}</h1>
                            <p>{rightcontent}</p>
                        </div>    
                     </a>
                </div>
                <div className={stylesR.resourcesItem}>
                    <div className={stylesR.resourcesItemImage}>
                        <a href={`${bleftFile}?dl=`}>
                        {bleftimage && bleftimage.asset && (
                        <GatsbyImage alt={bleftheader}  image={bleftimage.asset.gatsbyImageData}></GatsbyImage>
                        )}
                        </a>
                     </div>
                     <a href={`${bleftFile}?dl=`}>
                        <div className={stylesR.resourcesItemContent}>
                            <h1>{bleftheader}</h1>
                            <p>{bleftcontent}</p>
                        </div>
                     </a>        
                </div>
                
                
                     
                                {archives.map(archive => {
                                    
                                counter ++;
                                    return(
                                        <div>
                                            <div className={stylesR.resourcesItem}>
                                            <div className={stylesR.resourcesItemImage}>
                                            <a href={`${archive.file.asset.url}?dl=`}>
                                                {brightimage && brightimage.asset && (
                                                 <GatsbyImage alt={brightheader}  image={brightimage.asset.gatsbyImageData}></GatsbyImage>
                                                 )}
                                                 </a>
                                            </div>    

                                            <div className={stylesR.resourcesItemContent}>
                                            <a href={`${archive.file.asset.url}?dl=`}>
                                                <h1>{brightheader}</h1>
                                                <p>{brightcontent}</p>
                                                </a>
                                            </div>  
                                         </div>
                                      </div>
                                            // end of internal return
                                        );
                                            // end of mapping archives
                                    })}      

              
                
            </div>
       </div>
    </section>
    )
}

}

export default resourceArea