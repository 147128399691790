import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image';
import * as styles from '../../../sass/layout.module.scss'
import * as stylesS from '../shortSplit/shortSplit.module.scss'
import "../../../../src/styles/global.scss"


const shortSplit = ({block, raw}) => {
  const {header,subcontent,ctalink,ctalabel,photo,imagecheck,ctacheck} = block
  const check = 'short-'+imagecheck
  const check2 = 'shorter-'+imagecheck
  const checkheight = 'height-'+imagecheck
  const cta = 'cta-'+ctacheck
  const cta2 = 'cta-'+ctacheck + '-text'
 
  return (
    <section id={checkheight} className={stylesS.shortSplit}>
         <div className={styles.layoutBody}>
         
         <div id={check} className={stylesS.shortSplitWrap}>

         <div className={stylesS.shortSplitImage}>
                {photo && photo.asset && (
                <GatsbyImage 
                    alt={header} 
                    image={photo.asset.gatsbyImageData}>
                </GatsbyImage>
                )}
            </div>
         
          
         
          <div id={check2} className={stylesS.shortSplitContent}>

                <h1>{header}</h1>
                <p>{subcontent}</p>
                    
                <a className={stylesS.shortSplitCtaLink} href={ctalink}><div id={cta} className={stylesS.shortSplitCta}>
                    <p id={cta2} className={stylesS.shortSplitCtaText} >{ctalabel}</p>
                </div></a>

            </div>
            
           
           

        </div>

       </div>

    </section>
  )
}

export default shortSplit