import React from 'react'
import * as styles from '../../../sass/layout.module.scss'
import * as stylesI from './infoBar.module.scss'

const infoBar = ({block, raw}) => {
  const {mainitem,firstitem,seconditem,thirditem,fourthitem,firstLink,secondLink,thirdLink,fourthLink} = block

  return (
    <section>
    
        <div className={styles.layoutBody}>
            <div className={stylesI.infoBarWrapper}>
                <p className={stylesI.infoBarMain}>{mainitem}</p>
                <div className={stylesI.infoBarItems}>
                    <a href={firstLink}><p className={stylesI.infoBarItem}>{firstitem}</p></a>
                    <a href={secondLink}><p className={stylesI.infoBarItem}>{seconditem}</p></a>
                    <a href={thirdLink}><p className={stylesI.infoBarItem}>{thirditem}</p></a>
                    <a href={fourthLink}><p className={stylesI.infoBarItem}>{fourthitem}</p></a>
                </div>
            </div>

            <div id="support-mobile-menu" onClick={secondMenu} className={stylesI.infoBarMobile} role="textbox" tabIndex={0} onKeyDown={secondMenu}>
                <div className={stylesI.infoBarMobileText}>
                    <p>Jump To</p>
                </div>

                <div id="supportLinks" className={stylesI.infoMobileLinks}>
                    <a href="#campaign-area"><p className={stylesI.infoBarItem}>{firstitem}</p></a>
                    <a href="#membership"><p className={stylesI.infoBarItem}>{seconditem}</p></a>
                    <a href="#link-Donations"><p className={stylesI.infoBarItem}>{thirditem}</p></a>
                    <a href="#link-Volunteer"><p className={stylesI.infoBarItem}>{fourthitem}</p></a>
                </div>

            </div>
       </div>
    </section>
  )
}

export default infoBar


function secondMenu() {
  var y = document.getElementById("support-mobile-menu");
  y.classList.toggle("change");
  var x = document.getElementById("supportLinks");
  if (x.style.display === "block") {
    x.style.display = "none";

  } else {
    x.style.display = "block";


  }

}