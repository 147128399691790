import React from "react";
import companyLogo from "./main/companyLogo";
import contentBlock from "./main/contentBlock";
import blockGrid from "./main/blockGrid/blockGrid"
import heroHome from "./main/homeHero/heroHome";
import heroCarousel from "./main/heroCarousel/heroCarousel";
import disclaimer from "./main/disclaimer/disclaimer"
import splitImageRight from "./main/splitImageRight/splitImageRight"
import splitTextLeft from "./main/splitTextLeft/splitTextLeft"
import threeBlockText from "./main/threeBlockText/threeBlockText"
import rightTextHero from "./main/rightTextHero/rightTextHero"
import bottomSplitCta from "./main/bottomSplitCta/bottomSplitCta"
import topBanner from "./main/topBanner/topBanner"
import noLeftctaSplit from "./main/splitNoCtas/noLeftctaSplit"
import noRightctaSplit from "./main/splitNoCtas/noRightctaSplit"
import splitWithBackground from "./main/splitWithBackground/splitWithBackground"
import ourTeam from "./main/ourTeam/ourTeam"
import walkingTours from "./main/walkingTours/walkingTours"
import quadCta from "./main/quadCta/quadCta"
import shortSplit from "./main/shortSplit/shortSplit"
import infoBar from "./main/infoBar/infoBar";
import membership from "./main/membership/membership";
import triSplit from "./main/trisplit/trisplit";
import contactForm from "./main/contactForm/ContactForm"
import resourceArea from "./main/resources/resources"
import topSplit from "./main/topSplit/topSplit";
import events from "./main/Events/events";
import videoWithImage from "./main/videoWithImage/videoWithImage";
import board from "./Board"
import blogContent from "./main/blogContent/blogContent";
import splitBlogContent from "./main/splitBlogContent/splitBlogContent";
import Spacer from "./main/spacer/spacer";
import imageSection from "./main/imageSection/imageSection";

function PageBuilder(props) {
  const { type, pageBuilder, _rawPageBuilder } = props;

  // Load the right component, based on the _type from Sanity
  const Components = {
    contentBlock: contentBlock,
    companyLogo: companyLogo,
    blockGrid: blockGrid,
    heroHome: heroHome,
    heroCarousel: heroCarousel,
    disclaimer: disclaimer,
    splitImageRight: splitImageRight,
    splitTextLeft: splitTextLeft,
    threeBlockText: threeBlockText,
    rightTextHero: rightTextHero,
    bottomSplitCta: bottomSplitCta,
    topBanner: topBanner,
    noLeftctaSplit: noLeftctaSplit,
    noRightctaSplit: noRightctaSplit,
    splitWithBackground: splitWithBackground,
    ourTeam: ourTeam,
    walkingTours: walkingTours,
    quadCta: quadCta,
    shortSplit: shortSplit,
    infoBar: infoBar,
    membership: membership,
    triSplit: triSplit,
    contactForm: contactForm,
    resourceArea: resourceArea,
    topSplit: topSplit,
    events: events,
    videoWithImage: videoWithImage,
    board: board, 
    blogContent: blogContent,
    splitBlogContent: splitBlogContent,
    spacer: Spacer,
    imageSection: imageSection,

  };

  // 'raw' content needs to be passed in for the PortableText Component
  return pageBuilder.map((block, index) => {
    if (Components[block._type]) {
      return React.createElement(Components[block._type], {
        key: block._key,
        block: block,
        type,
        raw: _rawPageBuilder[index],
      });
    }
    return pageBuilder;
  });
}

export default PageBuilder;
