import React from 'react'
import * as styles from '../../../sass/layout.module.scss'
import * as stylesM from './membership.module.scss'
import { StaticImage } from "gatsby-plugin-image"


const membership = ({block, raw}) => {
  const {header} = block
  return (
    <section id="membership">
        <div className={styles.layoutBody}>
            <div className={stylesM.membershipWrapper}>
                <StaticImage src="../../../images/membership.png" alt={header} placeholder="blurred" layout="constrained" />
            </div>
            <div className={stylesM.membershipWrapperMobile}>
                <StaticImage src="../../../images/membershipMobile.png" alt={header} placeholder="blurred" layout="constrained" />
            </div>
       </div>
    </section>
  )
}

export default membership