import React from 'react';
import  '../../../styles/global.scss'



export default function Spacer ({ block,raw }) {
    const {spacerSize} = block
    var spacerClass = "spacer-" + spacerSize;
    
    return ( <div className={spacerClass}> </div> )
}