import React from 'react'
import * as stylesC from './contactForm.module.scss'
import "../../../../src/styles/contact.scss"
import JotformEmbed from 'react-jotform-embed';

const contactForm = ({block, raw}) => {
  const {name} = block
  const url = "https://form.jotform.com/" + name
  var id= 'test'
  
  
  if(name === 210466105188049){
    id='nominate-form'
  }
  else{
    id='campaign'
  }
  return (
    <div className={stylesC.contactWrap} id={id}>
      <JotformEmbed src={url} />
    </div>
  )
}

export default contactForm