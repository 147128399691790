import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image';
import * as styles from '../../../sass/layout.module.scss'
import * as stylesR from './rightTextHero.module.scss'

const rightTextHero = ({block, raw}) => {
  const {homeheader,subcontent,ctalabel,background, headerPos, contentPos, ctaPos, ctaLink} = block
    var headerStyle = {}
    var contentStyle = {}
    var ctaStyle = {}
    //header
    if(headerPos === null || headerPos === 'right'){
        headerStyle = {justifyContent: "flex-end"}
    }
    else if(headerPos === 'center'){
        headerStyle = {justifyContent: "center"}
    }
    else if(headerPos === 'left'){
        headerStyle = {justifyContent: "flex-start"}
    }
    //content
    if(contentPos === null || contentPos === 'right'){
        contentStyle = {justifyContent: "flex-end"}
    }
    else if(contentPos === 'center'){
        contentStyle = {justifyContent: "center"}
    }
    else if(contentPos === 'left'){
        contentStyle = {justifyContent: "flex-start"}
    }
    //cta
    if(ctaPos === null || ctaPos === 'right'){
        ctaStyle = {justifyContent: "flex-end"}
    }
    else if(ctaPos === 'center'){
        ctaStyle = {justifyContent: "center"}
    }
    else if(ctaPos === 'left'){
        ctaStyle = {justifyContent: "flex-start"}
    }

  return (
    <section className={stylesR.rightTextHero}>
       
        <div className={stylesR.rightTextImage}>
            {background && background.asset && (
                <GatsbyImage 
            alt={homeheader} 
            image={background.asset.gatsbyImageData}></GatsbyImage>
                )}
       </div>
       
       <div className={styles.layoutBody}>
        
            <div className={stylesR.rightTextContent}>
                <div style={headerStyle} className={stylesR.contentWrap}>
                    <h1>{homeheader}</h1>
                </div>
                <div style={contentStyle} className={stylesR.contentWrap}>
                    <p>{subcontent}</p>
                </div>
                
                <a style={ctaStyle} href={ctaLink}><div className={stylesR.rightTextCta}>
                    <p className={stylesR.rightTextCtaText}>{ctalabel}</p>
                </div></a>

            </div>
        
       </div> 


    </section>
  )
}

export default rightTextHero