import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image';
import * as styles from '../../../sass/layout.module.scss'
import * as stylesQ from '../quadCta/quadCta.module.scss'


const quadCta = ({block, raw}) => {
  const {header,subcontent,topleftheader,topleftcontent,topleftlink,topleftimage,toprightheader,toprightcontent,toprightlink,toprightimage,
    bottomleftheader,bottomleftcontent,bottomleftlink,bottomleftimage,bottomrightheader,bottomrightcontent,bottomrightlink,bottomrightimage} = block
  
  return (
    <section className={stylesQ.quadCta}>
        
        <div className={styles.layoutBody}>
            <h1>{header}</h1>
            <p>{subcontent}</p>

             <div className={stylesQ.quadCtaGrid}>

                <div className={stylesQ.quadCtaGridItem}>
                    <div className={stylesQ.quadCtaGridImage}>
                        {topleftimage && topleftimage.asset && (   
                        <GatsbyImage alt={topleftheader} image={topleftimage.asset.gatsbyImageData}></GatsbyImage>)}
                    </div>

                <div className={stylesQ.quadCtaGridContent}>
                    <h2>{topleftheader}</h2>
                    <p>{topleftcontent}</p>
                    <a href={topleftlink}><div className={stylesQ.quadCtaBox}>
                        Learn More
                    </div></a>
                 </div>

                </div>

                <div className={stylesQ.quadCtaGridItem}>
                    <div className={stylesQ.quadCtaGridImage}>
                        {toprightimage && toprightimage.asset && (   
                        <GatsbyImage alt={toprightheader} image={toprightimage.asset.gatsbyImageData}></GatsbyImage>)}
                    </div>
                    
                    <div className={stylesQ.quadCtaGridContent}>
                        <h2>{toprightheader}</h2>
                        <p>{toprightcontent}</p>
                        <a href={toprightlink}><div className={stylesQ.quadCtaBox}>
                           Learn More
                        </div></a>
                    </div>
                </div>

                <div className={stylesQ.quadCtaGridItem}>
                    <div className={stylesQ.quadCtaGridImage}>
                        {bottomleftimage && bottomleftimage.asset && (   
                        <GatsbyImage alt={bottomleftheader} image={bottomleftimage.asset.gatsbyImageData}></GatsbyImage>)}
                    </div>
                    
                    <div className={stylesQ.quadCtaGridContent}>
                        <h2>{bottomleftheader}</h2>
                        <p>{bottomleftcontent}</p>
                        <a href={bottomleftlink}><div className={stylesQ.quadCtaBox}>
                            Learn More
                        </div></a>
                    </div>
                </div>


                <div className={stylesQ.quadCtaGridItem}>
                    <div className={stylesQ.quadCtaGridImage}>
                        {bottomrightimage && bottomrightimage.asset && (   
                        <GatsbyImage alt={bottomrightheader} image={bottomrightimage.asset.gatsbyImageData}></GatsbyImage>)}
                    </div>
                    
                    <div className={stylesQ.quadCtaGridContent}>
                        <h2>{bottomrightheader}</h2>
                        <p>{bottomrightcontent}</p>
                        <a href={bottomrightlink}><div className={stylesQ.quadCtaBox}>
                            Learn More
                        </div></a>
                     </div>   
                </div>

            </div>

        </div>

    </section>
     // end of main return
  )
}

export default quadCta