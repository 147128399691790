import React from 'react'
import * as styles from '../../../sass/layout.module.scss'
import * as stylesW from '../blockGrid/blockGrid.module.scss'
import "../../../../src/styles/global.scss"
import { Link } from 'gatsby';

const blockGrid = ({block, raw}) => {
  const {header,subcontent,gridItems} = block
  var items = []

  gridItems.map(item => {
                var background = {}
                if (item.backgroundImage !==null){
                    background = { background: "url(" + item.backgroundImage.asset.gatsbyImageData.images.fallback.src +")"}
                }
                items.push(
                    <div className={stylesW.blockGridItem} style={background}>   
                            
                            <div className={stylesW.blockGridContent}>
                                    {(item.header) && (                    
                                        <h2>{item.header}</h2>
                                    )}
                                    {(item.content) && (
                                        <p>{item.content}</p>
                                    )}
                                    {(item.linkLabel) && (
                                        <Link to={item.link}>{item.linkLabel}</Link>
                                    )}
                            </div>   

                    </div>
                )
                return items
                        // end of mapping Block Grid
                  })
  return (
    <section className={stylesW.blockGrid}>
         <div className={styles.layoutBody}>
         {(header) && (       
            <h1>{header}</h1>
         )}
         {(subcontent) && (  
            <p>{subcontent}</p>
         )}
           <div className={stylesW.blockGridWrapper}>
            {items}
           </div>

        </div>

    </section>
     // end of main return
  )

}

export default blockGrid