import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image';
import * as styles from '../../../sass/layout.module.scss'
import * as stylesB from './topBanner.module.scss'
import "../../../../src/styles/global.scss"

const topBanner = ({block, raw}) => {
  const {hero,header} = block

  const heading = 'top-'+header;

  return (
    <section className={stylesB.topBanner}>
       
        <div className={stylesB.topBannerImage}>
            {hero && hero.asset && (
                <GatsbyImage 
            alt={header} 
            image={hero.asset.gatsbyImageData}></GatsbyImage>
                )}
       </div>
        
        <div className={heading}>
        <div className={styles.layoutBody}>
              <h1>{header}</h1>
                  
        </div>
       </div>


    </section>
  )
}

export default topBanner